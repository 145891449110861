.sidebar {
    height: calc(100% - 52px);
    position: fixed;
	top: 54px;
	width: 80px;
	background-color: black;
    overflow-y: auto;
}

.sidebar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
}

.sidebar::-webkit-scrollbar {
    width: 8px;
}

@media screen and (max-width: 768px) {
	.sidebar {
        width: 70px;
    }
}

@media (max-width: 460px) {
    .sidebar {
        display: flex;
        flex-direction: row;
        height: auto;
        padding: 8px 0;
        width: 100%;
        position: fixed;
        top: 50px;
        overflow-x: auto;
        overflow-y: unset;
        z-index: 10;
    }
}
