.container {
	flex: 1 0 auto;
	width: 30%;
	height: calc(100vw / 5.5);
	display: flex;
	flex-direction: column;
	position: relative;
	cursor: pointer;
	text-decoration: none;
}

.img-container {
	filter:brightness(0.8);
}

.img-container:hover {
	filter: brightness(1);
}

.img-container {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.img-container img {
	width: 100%;
	height: 100%;
}

.bullets {
	padding: 2px;
	position: absolute;
	bottom: 16px;
	left: 0;
	right: 0;
	height: 10px;
	display: flex;
	flex-direction: row;
	background: linear-gradient(30deg, #b9b9b9, #a7a7a7bc, #888888, #000);
}

.bullets p {
	padding: 0;
	margin: 0;
	color: black;
	font-size: 8.5px;
	font-style: italic;
}

.actions {
	width: 100%;
	height: 18px;
	background: linear-gradient(60deg, #7a7a7a, #909090bc, #eaeaea);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2;
}

.title-holder {
	position: absolute;
	bottom: 26px;
	left: 0;
	right: 0;
	height: 24%;
	background: linear-gradient(30deg, #b9b291, #b9b9b9, #a7a7a7bc, #888888, #7a7a7a);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4px;
}

.title {
	font-size: 14px;
	text-decoration: none;
	color: black;
	width: 90%;
	font-weight: 500;
}

.description {
	height: 14px;
	background-color: #000;
	position: absolute;
	left: 0;
	right: 0;
	top: -10px;
	overflow: hidden;
	border-left: 3px solid black;
	border-right: 3px solid black;
}

.marquee {
	font-size: 11px;
	color: yellow;
	white-space: nowrap;
	display: inline-block;
	padding: 0;
	margin: 0;
	top: -5px;
	position: relative;
	animation: marquee linear infinite;
	text-align: left;
}

@keyframes marquee {
	from {
	  transform: translateX(30%);
	}
	to {
	  transform: translateX(-100%);
	}
}

.icon-holder {
	height: 28px;
	width: 44px;
	position: absolute;
	right: 8px;
	top: 8px;
	z-index: 1;
	border-radius: 4px;
	overflow: hidden;
}

.icon-holder img {
	width: 100%;
	height: 100%;
}

.article-actions {
	height: 18px;
	display: flex;
	justify-content: space-between;
	background-color: transparent;
	margin-left: auto;
	color: black;
	padding-right: 4px;
}

.rate-btn {
	width: 20px;
	color: black;
	font-size: 11.5px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;
	z-index: 2;
	margin: 0 4px;
}

.article-actions p {
	margin: 0;
	padding: 0;
	padding-right: 4px;
	font-size: 12px;
	text-decoration: none;
	position: relative;
	top: 1px;
	left: -2px;
	width: auto;
}

.like:hover {
	color: darkgreen;
}

.dislike:hover {
	color: darkred;
}

.i-upvoted-it {
	color: green;
}

.i-downvoted-it {
	color: red;
}

.share-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
	padding-left: 4px;
	height: 18px;
}

.share-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    padding: 4px;
    padding-top: 6px;
    font-size: 10px;
    background-color: transparent;
    color: black;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: none;
	height: 18px;
}

.share-btn:hover {
    background-color: #6c757d;
    color: #fff;
}

.share-btn:last-child {
    margin-right: 0;
}

.action-btn {
    color: black;
	cursor: pointer;
	background-color: transparent;
	border: none;
	transition: all 0.2s ease-in-out;
	margin: 0;
	padding: 2px 4px;
}

.action-btn:hover {
    background-color: #6c757d;
    color: #fff;
}

.action-btn:last-child {
    margin-right: 0;
}

.comment-count {
    padding: 2px 4px;
    font-size: 10px;
    min-width: 16px;
    text-align: center;
}

.comment-icon-container {
   min-width: 40px;
}

@media screen and (max-width: 998px) {
	.container {
        width: 45%;
		height: calc(100vw / 4);
    }
	.title {
		font-size: 12px;
	}

	.marquee {
		font-size: 9px;
	}
}

@media screen and (max-width: 642px) {
	.container {
        width: 100%;
		height: calc(100vw / 1.6);
    }

	.title-holder {
		width: 100%;
		height: 22%;
	}

	.title {
		font-size: 14px;
	}

	.marquee {
		font-size: 11px;
	}
}

@media screen and (max-width: 460px) {
	.container {
        width: 100%;
		height: calc(100vw / 1.6);
    }

	.title-holder {
		width: 100%;
		height: 25%;
	}

	.title {
		font-size: 14px;
	}

	.actions {
		height: 20px;
	}

	.marquee {
		font-size: 11px;
	}
}

@media screen and (max-width: 300px) {
	.title {
		font-size: 11px;
	}
}
