:root {
  --rt-opacity: 1 !important;
}

p {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.App {
  text-align: center;
  min-height: 100vh;
  height: 100%;
  background-color: rgb(6, 12, 22);
}

:root::-webkit-scrollbar {
  width: 0px;
}

.vendors {
  flex: 0.9;
}

.vendor-filter img {
  width: 55px;
  height: 35px;
  margin: 8px;
}

.ReactModalPortal .ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
}

.modal-container {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(23, 44, 82);
  border-radius: 4px;
  outline: none;
}

.modal-container.modal-lg {
  height: 450px;
  width: 400px;
}

.modal-container button {
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: #ffffff;
  background-color: #2ea44f;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
}

.modal-container button:hover {
  background-color: #2c974b;
  border-color: #1b1f2326;
  transition-duration: 0.1s;
}

.modal-container form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 8px;
}


.modal-container input {
  padding: 5px 12px;
  font-size: 14px;
  line-height: 20px;
  color: #24292e;
  vertical-align: middle;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 8px center;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  outline: none;
  box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset;
  width: 60%;
  min-width: 50%;
}

.modal-container input:focus{
  border-color: #0366d6;
  outline: none;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

.modal-container form label {
  width: 60%;
  min-width: 50%;
  text-align: left;
  font-size: 14px;
  max-width: 80%;
  position: relative;
  bottom: -16px;
  left: -10px;
}

.error-modal {
  background-color: #ef1313;
}

.modal-container .modal-header {
  flex: 0.1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 6px;
}

.modal-container .modal-header p {
  font-size: 12px;
  color: white;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  margin-top: 6px;;
  font-style: italic;
}

.modal-container .modal-body {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
}

.modal-container .modal-body .login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.modal-container .modal-footer {
  flex: 0.1;
  width: 100%;
}

.modal-body p {
  text-align: center;
  margin: 8px;
}

.modal-body .ping-label {
  align-self: flex-start;
  left: unset;
  padding-left: 20px;
}

.modal-body .ping-input {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  width: calc(100% - 50px);
  color: white;
}

p.info {
  text-decoration: underline;
}

p.error {
  font-size: 0.75rem;
  font-style: italic;
  color: rgba(255, 255, 255, 0.8);
}

.pointer {
  cursor: pointer;
}

.modal-close {
  color: white;
  margin-left: auto;
  margin-right: 6px;
  margin-top: 4px;
  cursor: pointer;
  font-size: 12px;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(23, 44, 82);
}

.splash-screen img {
  max-width: 150px;
  max-height: 150px;
  filter: brightness(0) invert(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


@media screen and (max-width: 768px) {
  .vendor-filter img {
    width: 48px;
    height: 30px;
    margin: 6px;
  }
}

@media (max-width: 460px) {
  .modal-container {
    height: 200px;
    width: 80%;
  }
}
