.header {
    width: 100%;
    height: 52px;
    position: fixed;
    z-index: 100;
    top: 0;
    background-color: rgb(23, 44, 82);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo-holder {
    color: white;
    width: 200px;
    padding-left: 16px;
    padding-top: 0px;
}

.logo-holder img {
    width: 100%;
    height: 100%;
    filter: brightness(0) invert(1);
}

.search {
    flex: 1;
    height: 100%;
}

.search input {
    position: relative;
    left: -4px;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgb(169, 169, 169);
    color: white;
    margin-top: 12px;
    height: 20px;
    font-size: 20px;
    padding: 2px 0 6px;
    padding-left: 20px;
    width: 150px;
    transition: width 0.3s ease-in-out;
}

.search input:focus {
    border-bottom: 1px solid white;
    width: 300px;
}

.search-icon {
    color: white;
    position: relative;
    right: -12px;
}

.search-info p {
    margin: 8px 0;
}

.options {
    flex: 0.1;
	display: flex;
    flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	color: white;
    height: 100%;
    min-width: 64px;
}

.profile-holder {
    height: 100%;
    width: auto;
}

.profile-holder img {
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.logo-holder-icon {
    width: 40px !important;
}

/* Mobile Styles */
@media (max-width: 460px) {
    .search {
        flex-grow: 2;
    }

    .search input {
        width: 100px;
    }

    .search input:focus {
        width: 65%;
    }
}
