.container {
	width: calc(100% - 110px);
	display: flex;
	flex-direction: column;
	margin: 8px;
	margin-bottom: 0;
	margin-left: 88px;
	overflow: visible;
}

.article {
	width: 100%;
	height: 80px;
	color: white;
	border: 1px solid rgb(45, 45, 45);
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	padding-left: 8px;
	font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	overflow: visible;
}

.article:hover {
	border-color: rgb(110, 110, 110);
}

.article a {
	color: white;
	text-decoration: none;
	text-align: left;
	white-space: nowrap;
}

.article a:hover {
	text-decoration: underline;
}

.title-holder {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 8px 0;
	overflow: hidden;
}

.title-holder .link-holder {
	text-align: start;
}

.article .description {
	height: 10px;
	padding: 6px 0;
	font-size: 11px;
	color: rgb(154, 154, 154) !important;
}

.bullets {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 8px;
	margin-bottom: 8px;
	position: relative;
	top: -2px;
}

.bullets p {
	color: white;
	padding: 0;
	margin: 0;
	font-size: 10px;
	font-style: italic;
	user-select: none;
}

.bullets .author {
	margin-left: 4px;
}

.actions {
	display: flex;
	flex-direction: row;
}

.actions button {
	
}

.icon-holder {
	width: 34px;
	height: 18px;
	align-self: center;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	overflow: hidden;
	margin-left: 14px;
	margin-right: 14px;
	background-color: transparent;
}

.icon-holder img {
	width: 100%;
	max-width: 50px;
}

.tooltip {
	position: relative;
	z-index: 200;
}

.tooltip img {
	width: 300px;
	height: 200px;
	opacity: 1 !important;
}

.description {
	text-align: left;
	padding-right: 20px;
	font-size: 12px;
	font-style: italic;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.share {
	margin-left: auto;
	height: 18px;
	display: flex;
	flex-direction: row;
}

.share-btn {
	color: white;
	padding: 0 6px;
	cursor: pointer;
	background-color: transparent;
	margin-left: 4px;
	width: 20px;
}

button.share-btn {
	width: 34px;
}

.action-btn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid rgb(45, 45, 45);
	border-top: none;
	border-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	background-color: transparent;
	color: white;
	cursor: pointer;
	font-size: 10px;
}

.comment-btn {
	width: 34px;
	display: flex;
	justify-content: center;
	color: white;
	margin-left: 12px;
	margin-right: 4px;
	height: 18px;
	font-size: 11px;
	position: relative;
}

.comment-count {
	position: absolute;
	right: -6px;
	bottom: -6px;
	font-size: 7px;
	background-color: rgba(0, 0, 255, 0.6);
	border-radius: 50%;
	padding: 2px;
	width: auto;
	height: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2px;
	padding-left: 4px;
	padding-right: 4px;
}

.rate {
	height: 18px;
	min-width: 40px;
	border: 1px solid rgb(45, 45, 45);
	border-top: none;
	border-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-left: 8px;
	color: white;
}

.rate p {
	font-size: 12px;
	position: relative;
	left: -2px;
	padding-left: 4px;
	padding-right: 4px;
}

.rate-icon {
	height: 100%;
	width: 20px;
	font-size: 11.5px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.rate-icon p {
	margin-left: 0px;
	margin-right: 4px;
	font-size: 10px;
}

.i-upvoted-it {
	color: green;
	font-size: 13px;
}

.i-downvoted-it {
	color: red;
	font-size: 13px;
}

@media screen and (max-width: 768px) {
	.container {
		width: calc(100% - 100px);
		margin: 8px;
		margin-bottom: 0;
		margin-left: 70px;
	}

	.article a {
   		font-size: 14px;
		line-height: 16px;
  	}

	  .article .description {
		font-size: 10px;
	}

	.bullets p {
		font-size: 8px;
	}
}

@media (max-width: 460px) {
	.actions {
		width: 95%;
	}

	.container {
		width: 95%;
		margin-left: 8px;
		margin-right: 8px;
	}

	.article {
		align-self: center;
		width: 95%;
	}
}
