div input.comment-input {
    width: calc(100% - 120px);
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    color: white;
}

.comment-view {
    height: 110%;
    max-height: 350px;
    width: 95%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin-bottom: 8px;
    position: relative;
    top: -8px;
    overflow-y: auto;
}

.comment-view::-webkit-scrollbar {
    width: 12px;
}
  
.comment-view::-webkit-scrollbar-track {
    background: transparent;
}

.comment-view::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

.comment-view .comment {
    position: relative;
}

.comment-created-at {
    position: absolute;
    right: 0;
    top: -6px;
    font-size: 9px;
    color: #ccc;
}

.comment-author {
    font-size: 11px;
    color: #ccc;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 16px !important;
}

.input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.btn {
    padding: 2px;
    margin-left:12px;
}
