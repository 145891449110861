.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    color: green;
    font-size: 32px;
    animation: spin 1s linear infinite;
}
