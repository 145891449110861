.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 52px;
}

.view {
	width: calc(100% - 80px);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: 80px;
	margin-top: 10px;
	font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.options-bar {
  flex: 0.1;
  height: 40px;
  color: rgb(169, 169, 169);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 80px;
}

.options-bar p {
  text-align: left;
  padding-left: 0px;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.result-count {
  margin-left: 4px;
}

.view-switch {
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 8px;
  cursor: pointer;
}

@media (max-width: 768px) {
	.view {
		width: calc(100% - 70px);
		margin-left: 70px;
	}
}

@media (max-width: 460px) {
	.view {
		width: 100%;
		margin-left: 0px;
		overflow: hidden;
	}
	
	.options-bar {
		margin-top: 60px;
		margin-left: 8px;
		padding-right: 8px;
	}

	.view-switch {
		margin-right: 0px;
		width: 24px;
	}
}

